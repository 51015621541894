import { Component } from "react";
import { Navigate } from "react-router-dom";
import IOrder from "../types/order.type";
import { SyncShipping } from "./SyncShipping";
import { OrderList } from "./order/OrderList";

import React from "react";
import { AuthService } from "../services/auth.service";
import { OrderService } from "../services/order.service";

type Props = {};

type State = {
  loading: boolean;
  redirect: string | null;
  orders: Array<IOrder> | null;
};

export class Shipping extends Component<Props, State> {
  static displayName = Shipping.name;

  constructor(props: Props) {
    super(props);

    this.loadOrders = this.loadOrders.bind(this);
    this.state = {
      loading: false,
      redirect: null,
      orders: null,
    };
  }

  componentDidMount() {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      this.setState({
        redirect: "/",
      });
      return;
    }

    this.loadOrders();
  }

  loadOrders() {
    this.setState({ loading: true });
    OrderService.getOrdersToShip()
      .then((responseData) => {
        this.setState({
          orders: responseData,
          loading: false,
        });
      })
      .catch((err) => this.handleError(err));
  }

  handleError(error: any) {
    console.log(error);
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div>
        <div className="title-sync-grid">
          <h1>Shipping</h1>
          <div className="row ext-end">
            <SyncShipping />
          </div>
        </div>
        <OrderList
          initializing={this.state.loading}
          orders={this.state.orders}
          onReload={this.loadOrders}
        />
      </div>
    );
  }
}
