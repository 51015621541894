import { Component } from "react";
import ReactTimeAgo from "react-time-ago";
import { Button, Table } from "reactstrap";
import { TestTable } from "./TestTable";

import IProduct from "../../types/product.type";
import ITest from "../../types/test.type";

import React from "react";
import Images from "../../images/images";
import { ProductService } from "../../services/product.service";

type Props = {
  product: IProduct | null;
  tests: ITest[] | null;
  onRma: () => void;
  onRepair: () => void;
};

type State = {
  loading: boolean;
};

export class ProductTest extends Component<Props, State> {
  static displayName = ProductTest.name;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  testSuccessful = () => {
    this.setState({
      loading: true,
    });
    if (this.props?.product?.number) {
      ProductService.testSuccessful(this.props.product.number, "")
        .then((responseData) => {
          window.location.reload();
        })
        .catch((err) => this.handleError(err));
    }
  };

  testBatteryRepair = () => {
    this.setState({
      loading: true,
    });
    if (this.props?.product?.number) {
      ProductService.testBatteryRepair(this.props.product.number, "")
        .then((responseData) => {
          window.location.reload();
        })
        .catch((err) => this.handleError(err));
    }
  };

  handleError(error: any) {
    this.setState({
      loading: false,
    });
    console.log(error);
  }

  render() {
    const { loading } = this.state;

    let latestTest: ITest | undefined = undefined;
    if (this.props.tests) {
      latestTest = this.props.tests[0];
    }

    return (
      <div>
        {latestTest && this.props.product ? (
          <div>
            <div className="row">
              {latestTest.connectionTime ? (
                <div className="col text-right">
                  Connected:{" "}
                  <ReactTimeAgo
                    date={Date.parse(latestTest.connectionTime.toString())}
                    locale="en-US"
                    timeStyle="round"
                  />
                </div>
              ) : (
                <div className="col"></div>
              )}
            </div>

            <Table hover responsive size="sm" striped>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Expected product</td>
                  <td>{this.props.product.name}</td>
                </tr>
                <tr>
                  <td>M360 product</td>
                  <td>{latestTest.modelName}</td>
                </tr>
                <tr>
                  <td>IMEI</td>
                  <td>{latestTest.imei}</td>
                </tr>
                <tr>
                  <td>Expected grade</td>
                  <td>{this.props.product.condition}</td>
                </tr>
                <tr>
                  <td>M360 grade</td>
                  <td>{latestTest.grade}</td>
                </tr>
                <tr>
                  <td>Needs battery repair</td>
                  <td>
                    {this.props.product.hasGoodBatteryCapacity ? "No" : "Yes"}
                  </td>
                </tr>
                <tr>
                  <td>Battery capacity</td>
                  <td>
                    {this.props.product.batteryCapacity
                      ? this.props.product.batteryCapacity + "%"
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>Sold battery change</td>
                  <td>{this.props.product.soldBatteryChange ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Sold hydrogel</td>
                  <td>{this.props.product.soldHydrogel ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </Table>

            <div className="row">
              <div className="col">
                {this.props.product.hasGoodBatteryCapacity ? (
                  <Button
                    disabled={loading}
                    color="success"
                    className="m-1"
                    onClick={this.testSuccessful}
                    visible="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-lg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>{" "}
                    All good
                  </Button>
                ) : (
                  <div></div>
                )}
                <Button
                  disabled={loading}
                  color="info"
                  className="m-1"
                  onClick={this.testBatteryRepair}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-battery"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm2-1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H2zm14 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z" />
                  </svg>{" "}
                  Battery repair
                </Button>
                <Button
                  disabled={loading}
                  color="warning"
                  className="m-1"
                  onClick={this.props.onRepair}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-screwdriver"
                    viewBox="0 0 16 16"
                  >
                    <path d="m0 1 1-1 3.081 2.2a1 1 0 0 1 .419.815v.07a1 1 0 0 0 .293.708L10.5 9.5l.914-.305a1 1 0 0 1 1.023.242l3.356 3.356a1 1 0 0 1 0 1.414l-1.586 1.586a1 1 0 0 1-1.414 0l-3.356-3.356a1 1 0 0 1-.242-1.023L9.5 10.5 3.793 4.793a1 1 0 0 0-.707-.293h-.071a1 1 0 0 1-.814-.419L0 1zm11.354 9.646a.5.5 0 0 0-.708.708l3 3a.5.5 0 0 0 .708-.708l-3-3z" />
                  </svg>{" "}
                  Repair
                </Button>
                <Button
                  disabled={loading}
                  color="danger"
                  className="m-1"
                  onClick={this.props.onRma}
                >
                  <Images.RmaIcon /> RMA
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h4>Assign M360 tests</h4>
            <span>{this.props.product?.name}</span>
            <br />

            <Button
              disabled={loading}
              color="danger"
              className="m-1"
              onClick={this.props.onRma}
            >
              <Images.RmaIcon /> Not testable - RMA
            </Button>
            <br />
            <TestTable product={this.props.product} tests={this.props.tests} />
          </div>
        )}
      </div>
    );
  }
}
