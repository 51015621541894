import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import { ProductService } from "../../services/product.service";
import IProduct from "../../types/product.type";

type Props = {
  product: IProduct | null;
};

type State = {
  modal: boolean;
  loading: boolean;
  message: string;
  batteryCapacity?: number | null;
  hasGoodBatteryCapacity?: boolean | null;
};

export class ProductBattery extends Component<Props, State> {
  static displayName = ProductBattery.name;

  constructor(props: Props) {
    super(props);
    this.handleBatteryCapacity = this.handleBatteryCapacity.bind(this);

    this.state = {
      modal: false,
      loading: false,
      message: "",
      batteryCapacity: props.product?.batteryCapacity,
      hasGoodBatteryCapacity: props.product?.hasGoodBatteryCapacity,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  noCapacitySet = () => {
    if (this.props.product?.number) {
      ProductService.setBatteryCapacityNone(this.props.product.number)
        .then((responseData) => {
          window.location.reload();
        })
        .catch((err) => this.handleError(err));
    }
  };

  setBatteryMessage = () => {
    if (this.props.product?.number) {
      ProductService.setBatteryCapacityMessage(this.props.product.number)
        .then((responseData) => {
          window.location.reload();
        })
        .catch((err) => this.handleError(err));
    }
  };

  handleBatteryCapacity(formValue: { batteryCapacity?: number | null }) {
    const { batteryCapacity } = formValue;

    this.setState({
      message: "",
      loading: true,
    });

    if (this.props.product?.number && batteryCapacity) {
      ProductService.setBatteryCapacity(
        this.props.product.number,
        batteryCapacity
      )
        .then((responseData) => {
          window.location.reload();
        })
        .catch((err) => this.handleError(err));
    }
  }

  handleError(error: any) {
    this.setState({
      loading: false,
      message: "Error while saving. Check the console",
    });
    console.log(error);
  }

  validationSchema() {
    return Yup.object().shape({
      batteryCapacity: Yup.number()
        .typeError("Value from 1 to 100")
        .required("This field is required!")
        .min(0, "Min 1%")
        .max(100, "Max 100%"),
    });
  }

  render() {
    const { loading, message } = this.state;

    const initialValues = {
      batteryCapacity: this.state.batteryCapacity,
    };

    return (
      <div className="text-right">
        <span>
          {this.state.batteryCapacity
            ? " " + this.state.batteryCapacity + "% "
            : ""}
        </span>
        <Button
          color={
            this.state.hasGoodBatteryCapacity === true ? "success" : "danger"
          }
          onClick={this.toggle}
        >
          {this.state.hasGoodBatteryCapacity !== null ? (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-battery-full"
                viewBox="0 0 16 16"
              >
                <path d="M2 6h10v4H2V6z" />
                <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z" />
              </svg>
            </div>
          ) : (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-battery"
                viewBox="0 0 16 16"
              >
                <path d="M0 6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm2-1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H2zm14 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z" />
              </svg>
            </div>
          )}
        </Button>

        <Modal
          centered
          fade={false}
          size=""
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}>
            <span>
              Set battery capacity from product {this.props.product?.number}
            </span>
          </ModalHeader>
          <ModalBody>
            <span className="font-italic">
              Settings -{">"} Battery -{">"} Battery health -{">"} Battery
              capacity
            </span>

            <div className="col-md-12">
              <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.handleBatteryCapacity}
              >
                <Form>
                  <div className="form-group">
                    <Field
                      name="batteryCapacity"
                      type="text"
                      className="form-control m-1"
                    />
                    <ErrorMessage
                      name="batteryCapacity"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block m-1"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span> Save</span>
                    </button>{" "}
                    <Button
                      onClick={this.setBatteryMessage}
                      className="btn btn-info btn-block m-1"
                    >
                      Battery message
                    </Button>{" "}
                    <Button
                      onClick={this.noCapacitySet}
                      className="btn btn-warning btn-block m-1"
                    >
                      No capacity
                    </Button>{" "}
                    <Button onClick={this.toggle} className="m-1">
                      Cancel
                    </Button>
                  </div>

                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                </Form>
              </Formik>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
