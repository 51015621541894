import axios from "axios";
import {
  IFindRelatedOrder,
  IProductIssue,
  IRelatedOrder,
} from "../types/productissue.type";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/productissue";

export class ProductIssueService {
  public static async fetchRelatedOrder(
    findRelatedOrder: IFindRelatedOrder
  ): Promise<IRelatedOrder> {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return await axios
      .post(API_URL + "/fetchOrder", findRelatedOrder, config)
      .then((response) => response.data);
  }

  public static async createProductIssue(productIssue: IProductIssue) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    await axios.post(API_URL + "/createIssue", productIssue, config);
  }
}
