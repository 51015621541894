import * as React from "react";
import { Component } from "react";
import { AuthService } from "../services/auth.service";

type Props = {};

type State = {
  authenticated: boolean;
  username: string | null;
};

export class Home extends Component<Props, State> {
  static displayName = Home.name;

  constructor(props: Props) {
    super(props);

    this.state = {
      authenticated: false,
      username: null,
    };
  }

  componentDidMount() {
    const userToken = AuthService.getCurrentUser();
    const authenticated = AuthService.isUserAuthenticated();
    this.setState({
      authenticated: authenticated,
      username: userToken.username,
    });
  }

  render() {
    return (
      <div>
        {this.state.authenticated ? (
          <div>
            <h1>Welcome {this.state.username}!</h1>
          </div>
        ) : (
          <div>
            <h1>Welcome at Mobileup Operations</h1>
            <p>
              If you're not working at mobileup and if you landed here by
              accident:{" "}
              <a href="https://www.mobileup.ch">
                go here, you might find some refurbished phones you like!
              </a>
            </p>
          </div>
        )}
      </div>
    );
  }
}
