import axios from "axios";
import IUserAction from "../types/useraction.type";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/useraudit";

export class UserAuditService {
  public static async getUserActionsByOrderRef(orderNr: string) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    const response = await axios.get(API_URL + "/byorder/" + orderNr, config);
    return response.data as IUserAction[];
  }

  public static async getUserActionsByProductRef(productRef: string) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    const response = await axios.get(
      API_URL + "/byproduct/" + productRef,
      config
    );
    return response.data as IUserAction[];
  }

  public static async getUserActionsByUser(user: string) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/byuser/" + user, config)
      .then((res) => res.data as IUserAction[]);
  }

  public static async getLatestUserActions() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    const response = await axios.get(API_URL + "/latest", config);
    return response.data as IUserAction[];
  }
}
