import { ReactComponent as BiPhone } from "./icons/bi-phone.svg";
import { ReactComponent as BiPrinter } from "./icons/bi-printer.svg";
import { ReactComponent as DoubleArrowIcon } from "./icons/doublearrow.svg";
import { ReactComponent as ProductTestIcon } from "./icons/producttest.svg";
import { ReactComponent as RmaIcon } from "./icons/rma.svg";

const Images = {
  DoubleArrowIcon,
  ProductTestIcon,
  RmaIcon,
  BiPhone,
  BiPrinter,
};

export default Images;
