import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { ProductRegisterService } from "../../services/productregister.service";
import { ZebraService } from "../../services/zebra.service";
import { useOpsToolStore } from "../../store/OpsStore";
import IRegisterItem from "../../types/registeritem.type";

// Extend if dynamic countries needed
const availableCountries = ["CH"];

export const ProductRegister = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [searchInput, setSearchInput] = useState("");
  const [registerItems, setRegisterItems] = useState<IRegisterItem[]>([]);
  const [currentImei, setCurrentImei] = useState<string | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<IRegisterItem | undefined>(
    undefined
  );
  const [countryLocation, setCountryLocation] = useOpsToolStore((state) => [
    state.countryLocation,
    state.setRegisterCountryLocation,
  ]);

  useEffect(() => {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      setRedirect("/");
    }
  }, []);

  useEffect(() => {
    if (availableCountries.some((country) => country !== countryLocation)) {
      setCountryLocation(availableCountries[0]);
    }
  }, [countryLocation, setCountryLocation]);

  const doSearch = async () => {
    setIsLoading(true);
    try {
      const purchaseItems = await ProductRegisterService.getPurchaseItems(
        searchInput
      );
      setRegisterItems(purchaseItems);
      setErrorMessage(undefined);
    } catch (e) {
      setErrorMessage("Error fetching purchase item");
      console.error("Error fetching purchaseItem", e);
    }
    setIsLoading(false);
  };

  const onInputChange = (text: string) => {
    setSearchInput(text);
  };

  const onEdit = (item: IRegisterItem) => {
    setSelectedItem(item);
  };

  const onDialogClose = () => {
    setSelectedItem(undefined);
    setCurrentImei(undefined);
  };

  const onRegister = async () => {
    setIsLoading(true);

    const item = selectedItem;
    const imei = currentImei?.trim();

    // IMEI is 15 chars but for devices without mobile access, product number (5 or more chars) is scanned
    if (item && imei && imei.length >= 5 && imei.length <= 15) {
      setSelectedItem(undefined);
      setCurrentImei(undefined);

      try {
        // Register product on backend
        await ProductRegisterService.register({
          purchaseItemCrmGuid: item.purchaseItemCrmGuid,
          refurbedProductGuid: item.refurbedProductGuid,
          refurbedProductRef: String(item.refurbedProductRef),
          imei: imei,
          country: countryLocation,
        });
        // Print label
        await ZebraService.printLabel(
          String(item.refurbedProductRef),
          false,
          setErrorMessage
        );
        // Remove our registered item from the list
        setRegisterItems(
          registerItems.filter(
            (registerItem) =>
              item.purchaseItemCrmGuid !== registerItem.purchaseItemCrmGuid
          )
        );
        setErrorMessage(undefined);
      } catch (e) {
        setErrorMessage("Error registering product");
        console.error("Error registering product", e);
      }
    }

    setIsLoading(false);
  };

  const onSearchTextFieldKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // enter key
      doSearch();
    }
  };

  const onImeiTextFieldKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // enter key
      onRegister();
    }
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  let tableBody = (
    <TableRow
      key="loading"
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!loading) {
    tableBody = (
      <>
        {registerItems.map((row) => (
          <TableRow
            key={row.purchaseItemCrmGuid}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.refurbedProductRef}
            </TableCell>
            <TableCell>{row.refurbedOfferName}</TableCell>
            <TableCell>{row.hasBatteryReplacement ? "yes" : "no"}</TableCell>
            <TableCell>{row.type.name}</TableCell>
            <TableCell>
              <IconButton
                onClick={() => onEdit(row)}
                disabled={loading}
                color="primary"
                aria-label="register"
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  let errorMessageElem = undefined;
  if (errorMessage) {
    errorMessageElem = (
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  }

  const modal = (
    <Dialog
      open={selectedItem !== undefined}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Register Item as Received
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            autoFocus
            required
            value={currentImei}
            onChange={(ev) => setCurrentImei(ev.target.value)}
            margin="dense"
            id="name"
            name="imei"
            label="IMEI / ID"
            fullWidth
            variant="standard"
            onKeyDown={onImeiTextFieldKey}
            inputProps={{
              maxlength: "15",
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="success" variant="outlined" onClick={onRegister}>
          Register
        </Button>
        <Button color="error" variant="outlined" onClick={onDialogClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {modal}
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row">
          <TextField
            autoFocus
            id="outlined-basic"
            label="Purchase Number"
            variant="filled"
            required
            onChange={(ev) => onInputChange(ev.target.value)}
            onKeyDown={onSearchTextFieldKey}
            onFocus={(event) => {
              // Select all text when clicking on text field
              event.target.select();
            }}
          />
          <IconButton
            onClick={doSearch}
            disabled={loading}
            color="primary"
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Stack>

        <Stack style={{ paddingTop: "10px" }} direction="row-reverse">
          <Autocomplete
            disablePortal
            autoHighlight
            disableClearable
            id="country-location-select"
            options={availableCountries}
            value={countryLocation}
            sx={{ width: 250 }}
            size="small"
            onChange={(_, val) => setCountryLocation(val)}
            renderInput={(params) => (
              <TextField {...params} label="Country Location" />
            )}
          />
        </Stack>
      </Stack>
      {errorMessageElem}
      <div style={{ padding: "10px" }} />
      <Stack spacing={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Refurbed Product ID</b>
                </TableCell>
                <TableCell>
                  <b>Refurbed Offer</b>
                </TableCell>
                <TableCell>
                  <b>Battery Replacement</b>
                </TableCell>
                <TableCell>
                  <TableSortLabel active={true}>
                    <b>Type</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableBody}</TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </div>
  );
};
