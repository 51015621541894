export interface IFindRelatedOrder {
  identifier: string;
}

export interface IRelatedOrder {
  orderNumber: string;
  orderCrmGuid: string;
  orderItemCrmGuid: string;
  refurbedProductCrmGuid: string;
  refurbedProductRef: string;
}

export enum ProductIssueType {
  WARRANTY = "WARRANTY",
  MONEY_BACK = "MONEY_BACK",
}

export interface IProductIssue {
  orderItemCrmGuid: string;
  orderNumber: string;
  refurbedProductRef: string;
  issueType: ProductIssueType;
  notes: string;
}
