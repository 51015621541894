import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { StockScanService } from "../../services/stockscan.service";

interface StockLocationRow {
  location: string;
  timestamp: Date;
  user: string;
}

export const ProductSearch = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [loading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [stockHistoryRows, setStockHistoryRows] = useState<StockLocationRow[]>(
    []
  );

  useEffect(() => {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      setRedirect("/");
    }
  }, []);

  const doSearch = async () => {
    setIsLoading(true);
    const stockLocations = await StockScanService.getStockScans(searchInput);
    setStockHistoryRows(
      stockLocations.map((stockLocation) => {
        return {
          location: `${stockLocation.location} (${stockLocation.countryCode})`,
          timestamp: new Date(stockLocation.timestamp),
          user: stockLocation.user,
        };
      })
    );
    setIsLoading(false);
  };

  const onInputChange = (text: string) => {
    setSearchInput(text);
  };

  const onTextFieldKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // enter key
      doSearch();
    }
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  let tableBody = (
    <TableRow
      key="loading"
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!loading) {
    tableBody = (
      <>
        {stockHistoryRows.map((row) => (
          <TableRow
            key={row.timestamp.toUTCString()}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.location}
            </TableCell>
            <TableCell>{row.timestamp.toLocaleString()}</TableCell>
            <TableCell>{row.user}</TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row">
        <TextField
          id="outlined-basic"
          label="Product Reference"
          variant="filled"
          autoFocus
          onChange={(ev) => onInputChange(ev.target.value)}
          onKeyDown={onTextFieldKey}
          onFocus={(event) => {
            // Select all text when clicking on text field
            event.target.select();
          }}
        />
        <IconButton
          onClick={doSearch}
          disabled={loading}
          color="primary"
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Stack>
      <Divider />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Location</b>
              </TableCell>
              <TableCell>
                <b>Timestamp</b>
              </TableCell>
              <TableCell>
                <b>User</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
      <span>A maximum of 20 stock location history entries is displayed.</span>
    </Stack>
  );
};
