import { Button } from "@mui/material";
import QrScanner from "qr-scanner";
import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { StockScanService } from "../../services/stockscan.service";

export const Scanning = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [showPhoneScanner, setShowPhoneScanner] = useState(false);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [location, setLocation] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!AuthService.isUserAuthenticated()) {
      setRedirect("/");
    }
  }, []);

  useEffect(() => {
    let qrScanner: QrScanner | null = null;

    const handleQrScan = (data: string) => {
      if (!location) {
        setLocation(data);
      } else if (!phone) {
        setPhone(data);
      }
    };

    if (videoRef.current) {
      qrScanner = new QrScanner(
        videoRef.current,
        (result) => handleQrScan(result.data),
        {
          onDecodeError: (error) => console.error(error),
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      qrScanner.start();
    }

    /*return () => qrScanner?.destroy();*/
    return () => {
      if (qrScanner) {
        qrScanner.stop();
        qrScanner.destroy();
        qrScanner = null;
      }
    };
  }, [phone, location, showPhoneScanner]);

  // One priner (Dynamo) adds "URL:" to the beginning of the QR code, so we need to remove it
  const cleanProductRef = (productRef: string) => {
    if (productRef.startsWith("URL:")) {
      productRef = productRef.slice(4);
    }
    return productRef;
  };

  const handleScan = async () => {
    if (!phone || !location) {
      return;
    }

    try {
      await StockScanService.registerStockScan({
        productRef: cleanProductRef(phone),
        location: location,
        timestamp: new Date().toISOString(),
      });
    } catch (error) {
      alert(error);
      console.error(error);
    } finally {
      setPhone(undefined);
      setShowPhoneScanner(false);
    }
  };

  const renderScanner = (width: string) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <video ref={videoRef} style={{ width }}></video>
    </div>
  );

  const renderButtons = (buttons: JSX.Element[]) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      {buttons}
    </div>
  );

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <>
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          width: "400px",
          height: "60vh",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1>Stock Scanner</h1>
        {!location ? (
          <>
            <h2>Please set Location</h2>
            {renderScanner("300px")}
            <div style={{ flexGrow: 1 }}></div>
            <Button
              variant="outlined"
              style={{
                marginTop: "20px",
                fontSize: "18px",
                padding: "10px 20px",
              }}
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </Button>
          </>
        ) : location && !phone && !showPhoneScanner ? (
          <>
            <h2>Location: {location}</h2>
            <div style={{ flexGrow: 1 }}></div>
            {renderButtons([
              <Button
                key="start-scan"
                variant="outlined"
                style={{
                  fontSize: "18px",
                  padding: "10px 20px",
                  marginBottom: "20px",
                }}
                onClick={() => setShowPhoneScanner(true)}
              >
                Scan Device
              </Button>,
            ])}
          </>
        ) : location && !phone && showPhoneScanner ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ margin: 0, paddingRight: "10px" }}>
                Location: {location}{" "}
              </h2>
              {renderButtons([
                <Button
                  key="change-location"
                  variant="outlined"
                  style={{ fontSize: "10px", padding: "10px 10px" }}
                  onClick={() => {
                    setLocation(undefined);
                    setShowPhoneScanner(false);
                  }}
                >
                  Change Location
                </Button>,
              ])}
            </div>
            <div style={{ margin: "20px" }}></div>
            {renderScanner("300px")}
          </>
        ) : (
          <>
            <h2>Location: {location}</h2>
            <h2>Phone: {phone}</h2>
            {renderScanner("300px")}
            <div style={{ flexGrow: 1 }}></div>
            {renderButtons([
              <Button
                key="delete-scan"
                color="error"
                variant="outlined"
                style={{ fontSize: "18px", padding: "10px 20px" }}
                onClick={() => {
                  setPhone(undefined);
                  setShowPhoneScanner(false);
                }}
              >
                Delete Scan
              </Button>,
              <Button
                key="scan-ok"
                color="success"
                variant="outlined"
                style={{ fontSize: "18px", padding: "10px 20px" }}
                onClick={() => {
                  handleScan();
                }}
              >
                Scan is OK
              </Button>,
            ])}
          </>
        )}
      </div>
    </>
  );
};
