import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Images from "../../images/images";
import { ZebraService } from "../../services/zebra.service";
import IProduct from "../../types/product.type";

type Props = {
  product: IProduct | null;
};

type State = {
  modal: boolean;
  loading: boolean;
  message: string;
};

export class ProductLabelPrint extends Component<Props, State> {
  static displayName = ProductLabelPrint.name;

  constructor(props: Props) {
    super(props);

    this.state = {
      modal: false,
      loading: false,
      message: "",
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  printLabel = async () => {
    this.setState({
      message: "",
      loading: true,
    });

    await ZebraService.printLabel(
      this.props.product?.number,
      false,
      (errorMessage) => this.setState({ message: errorMessage })
    );

    this.setState({
      modal: !this.state.modal,
      loading: false,
    });
  };

  handleError(error: any) {
    this.setState({
      loading: false,
      message: "Error while saving. Check the console",
    });
    console.log(error);
  }

  render() {
    const { loading, message } = this.state;

    return (
      <div className="text-center">
        <Button onClick={this.toggle}>
          <Images.BiPrinter />
        </Button>

        <Modal
          centered
          fade={false}
          size=""
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}>
            <span>Print label for product {this.props.product?.number}</span>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary btn-block m-1"
                  disabled={loading}
                  onClick={this.printLabel}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span> Print label</span>
                </button>{" "}
                <Button onClick={this.toggle} className="m-1">
                  Cancel
                </Button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
