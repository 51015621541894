import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";

import React from "react";
import { SyncService } from "../services/sync.service";

export const SyncMasterData = (): JSX.Element => {
  const [loading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [lastSync, setLastSync] = useState<string | undefined>(undefined);

  useEffect(() => {
    SyncService.getLastMasterDataSync()
      .then((responseData) => {
        if (responseData && responseData.end) {
          setLastSync(responseData.end.toString());
        } else {
          setLastSync(undefined);
        }
      })
      .catch((err) => handleError(err));
  }, []);

  const handleError = (error: any): void => {
    setIsLoading(false);
    setMessage("Error while sync data. Check the console");
    console.log(error);
  };

  return (
    <div className="row">
      <div className="col-9">
        <div>BigDB Sync</div>
        <div>
          {lastSync ? (
            <ReactTimeAgo
              date={Date.parse(lastSync)}
              locale="en-US"
              timeStyle="round"
            />
          ) : (
            "??? ago"
          )}
        </div>
      </div>
    </div>
  );
};
