import axios from "axios";
import IRegisterItem from "../types/registeritem.type";
import IRegisterProduct from "../types/registerproduct.type";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/product";

export class ProductRegisterService {
  public static async getPurchaseItems(purchaseNr: string) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    try {
      const response = await axios.get(
        API_URL + "/toregister/" + purchaseNr,
        config
      );
      return response.data as IRegisterItem[];
    } catch (message) {
      console.error(message);
      return [];
    }
  }

  public static async register(item: IRegisterProduct) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios.post(API_URL + "/registerProduct", item, config);
  }
}
