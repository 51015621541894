import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { UserAuditService } from "../../services/useraudit.service.ts";
import IUserAction from "../../types/useraction.type";

enum SearchType {
  PRODUCT_REF = "ProductRef",
  ORDER_REF = "OrderRef",
  USER = "User",
}

export const UserAuditing = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [searchInput, setSearchInput] = useState("");
  const [userActions, setUserActions] = useState<IUserAction[]>([]);
  const [searchType, setSearchType] = useState<SearchType>(SearchType.USER);

  useEffect(() => {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      setRedirect("/");
    }

    UserAuditService.getLatestUserActions().then((userActions) =>
      setUserActions(userActions)
    );
  }, []);

  const doSearch = async () => {
    setIsLoading(true);
    try {
      let apiCall: Promise<IUserAction[]>;
      if (searchInput.trim().length === 0) {
        apiCall = UserAuditService.getLatestUserActions();
      } else {
        switch (searchType) {
          case SearchType.USER:
            apiCall = UserAuditService.getUserActionsByUser(searchInput);
            break;
          case SearchType.PRODUCT_REF:
            apiCall = UserAuditService.getUserActionsByProductRef(searchInput);
            break;
          case SearchType.ORDER_REF:
            apiCall = UserAuditService.getUserActionsByOrderRef(searchInput);
            break;
        }
      }
      const actions = await apiCall;
      setUserActions(actions);
    } catch (e) {
      setErrorMessage("Error fetching purchase item");
      setUserActions([]);
      console.error("Error fetching purchaseItem", e);
    }
    setIsLoading(false);
  };

  const onInputChange = (text: string) => {
    setSearchInput(text);
  };

  const onSearchTextFieldKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // enter key
      doSearch();
    }
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  let tableBody = (
    <TableRow
      key="loading"
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!loading) {
    tableBody = (
      <>
        {userActions.map((row) => (
          <TableRow
            key={`${row.user}-${row.timestamp}`}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.user}
            </TableCell>
            <TableCell>{new Date(row.timestamp).toLocaleString()}</TableCell>
            <TableCell>{row.action}</TableCell>
            <TableCell>{row.relatedProductRef}</TableCell>
            <TableCell>{row.relatedOrderRef}</TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  let errorMessageElem = undefined;
  if (errorMessage) {
    errorMessageElem = (
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  }

  return (
    <div>
      <Stack direction="column" spacing={3}>
        <Autocomplete
          disablePortal
          autoHighlight
          disableClearable
          id="search-type-select"
          options={Object.values(SearchType)}
          value={searchType}
          sx={{ width: 250 }}
          size="small"
          onChange={(_, val) => setSearchType(val)}
          renderInput={(params) => (
            <TextField {...params} label="Search Type" />
          )}
        />
        <Stack direction="row">
          <TextField
            autoFocus
            style={{ minWidth: "300px" }}
            id="outlined-basic"
            label={searchType}
            variant="filled"
            required
            onChange={(ev) => onInputChange(ev.target.value)}
            onKeyDown={onSearchTextFieldKey}
            onFocus={(event) => {
              // Select all text when clicking on text field
              event.target.select();
            }}
          />
          <IconButton
            onClick={doSearch}
            disabled={loading}
            color="primary"
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Stack>
      </Stack>
      {errorMessageElem}
      <div style={{ padding: "10px" }} />
      <Stack spacing={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Username</b>
                </TableCell>
                <TableCell>
                  <b>Timestamp</b>
                </TableCell>
                <TableCell>
                  <b>Action Type</b>
                </TableCell>
                <TableCell>
                  <b>Related Product ID</b>
                </TableCell>
                <TableCell>
                  <b>Related Order</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableBody}</TableBody>
          </Table>
        </TableContainer>
        <span>A maximum of 50 user actions are displayed.</span>
      </Stack>
    </div>
  );
};
