import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import TimeAgo from "javascript-time-ago";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import en from "javascript-time-ago/locale/en.json";
import React from "react";
import { AuthService } from "./services/auth.service";

TimeAgo.addDefaultLocale(en);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

//axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.timeout = 1000 * 60;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Received unauthorized from backend -> logging out");
      AuthService.logout();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter basename={baseUrl ?? undefined}>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
