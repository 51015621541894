import { ProductService } from "./product.service";

const ZebraBrowserPrintWrapper = require("zebra-browser-print-wrapper");

export class ZebraService {
  public static async print(zpl: string) {
    try {
      console.log("init");
      const browserPrint = new ZebraBrowserPrintWrapper.default();
      console.log("get default printer");
      const defaultPrinter = await browserPrint.getDefaultPrinter();
      console.log("set printer");
      browserPrint.setPrinter(defaultPrinter);
      console.log("print");
      browserPrint.print(zpl);
    } catch (error) {
      console.log(zpl);
      alert("Printing failed: " + error);
    }
  }

  public static async printLabel(
    productNumber: string | undefined,
    showSupplierWarranty: boolean,
    setErrorMessage: (errorMessage: string) => void
  ) {
    if (productNumber) {
      try {
        const responseData = await ProductService.getProductLabel(
          productNumber,
          showSupplierWarranty
        );
        if (responseData && responseData.labelZpl) {
          await ZebraService.print(responseData.labelZpl);
        } else {
          setErrorMessage("Did not receive any label from backend");
          console.log("Did not receive labelZpl in response", responseData);
        }
      } catch (err) {
        setErrorMessage("Unknown error, see console for details");
        console.log("Error printing", err);
        return;
      }
    }
  }
}
