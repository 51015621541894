import axios, { ResponseType } from "axios";
import LabelType from "../services/labeltype.enum";
import IOrder from "../types/order.type";
import IPostDataTransferResponse from "../types/postdatatransferresponse.type";
import IShippedAllResponse from "../types/shippedallresponse.type";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/order";

export class OrderService {
  public static async getOrdersToShip(): Promise<IOrder[]> {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };

    const response = await axios.get(API_URL + "/toship", config);
    return response.data as IOrder[];
  }

  public static async getOrdersShippedToday(): Promise<IOrder[]> {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };

    const response = await axios.get(API_URL + "/shippedtoday", config);
    return response.data as IOrder[];
  }

  public static async postShippedTodayOrders(
    orderIdsToInclude: Set<number>
  ): Promise<IPostDataTransferResponse> {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };

    const response = await axios.post(
      API_URL + "/postShippedTodayOrders",
      {
        IncludedOrders: [...orderIdsToInclude],
      },
      config
    );

    return response.data as IPostDataTransferResponse;
  }

  public static generateLabel(id: number, labelType: LabelType) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/" + id + "/generateLabel/" + labelType, config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static resetTracking(id: number) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/resetTracking",
        {
          id,
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static updateOrderAddress(
    orderNumber: string,
    orderCrmGuid: string,
    newAddress: string
  ) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/changeOrderAddress",
        {
          orderNumber,
          orderCrmGuid,
          contactAddress: newAddress,
        },
        config
      )
      .then((response) => {
        return response.data;
      });
  }

  public static getLabel(id: number) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/" + id + "/getLabel", config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static async markCollected(
    orderItemId: number,
    collected: boolean
  ): Promise<void> {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };

    return await axios.post(
      API_URL + "/orderItemCollected",
      {
        orderItemId,
        collected,
      },
      config
    );
  }

  public static async shippedAll(
    id: number
  ): Promise<IShippedAllResponse | undefined> {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    try {
      const response = await axios.post(
        API_URL + "/shippedAll",
        {
          id,
        },
        config
      );

      if (response.status >= 400) {
        console.log(`Error in shippedAll request: ${response.data}`);
        return undefined;
      }
      return response.data as IShippedAllResponse;
    } catch (message) {
      console.error(message);
    }
  }

  public static getPostXmlResponse() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios.get(API_URL + "/postXmlResponse", config).then((response) => {
      return response.data;
    });
  }

  public static async getPostXmlResponseFile(): Promise<void> {
    const config = {
      headers: AuthService.getAuthHeaders(),
      responseType: "blob" as ResponseType,
    };

    return await axios
      .get(API_URL + "/postXmlResponseFile", config)
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "post.pdf");
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }
}
