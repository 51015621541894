enum PaymentMethod {
  INVALID_PAYMENT_METHOD = "INVALID_PAYMENT_METHOD",

  Kreditkarte = "Kreditkarte",
  Paypal = "Paypal",
  Raten_Ideal = "Raten_Ideal",
  Rechnung = "Rechnung",
  Rechnung_Ideal = "Rechnung_Ideal",
  TWINT = "TWINT",
  Vorkasse = "Vorkasse",
}

export default PaymentMethod;
