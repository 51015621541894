import axios from "axios";
import IStockScan from "../types/stockscan.type";
import IStockScanFromQr from "../types/stockscanfromqr.type";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/stockscan";

export class StockScanService {
  public static async getStockScans(productRef: string) {
    const config = {
      params: { limit: 20 },
      headers: AuthService.getAuthHeaders(),
    };
    try {
      const response = await axios.get(API_URL + "/" + productRef, config);
      return response.data as IStockScan[];
    } catch (message) {
      console.log(message);
      return [];
    }
    }
    public static async registerStockScan(stockScan: IStockScanFromQr) {
        const config = {
            headers: AuthService.getAuthHeaders(),
        };
        try {
            await axios.post(API_URL, stockScan, config);
        } catch (message) {
            alert(message);
            console.log(message);
        }


    }
}
