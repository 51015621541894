import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { Component } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AuthService } from "../services/auth.service";

type State = {
  username: string;
  password: string;
  loading: boolean;
  message: string;
};

type Props = {
  navigate: (url: string) => void;
};

// TODO: Rewrite as functional component
class LoginInternal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  validationSchema() {
    return Yup.object().shape({
      username: Yup.string().required("This field is required!"),
      password: Yup.string().required("This field is required!"),
    });
  }

  handleLogin(formValue: { username: string; password: string }) {
    const { username, password } = formValue;

    this.setState({
      message: "",
      loading: true,
    });

    AuthService.login(username, password)
      .then((responseData) => {
        if (responseData.token) {
          this.props.navigate("/");
          window.location.reload();
        } else {
          this.setState({
            loading: false,
            message: "Invalid username or password",
          });
        }
      })
      .catch((err) => this.handleError(err));
  }

  handleError(error: any) {
    this.setState({
      loading: false,
      message: "Error while log in. Check the console",
    });
    console.log(error);
  }

  render() {
    const { loading, message } = this.state;

    const initialValues = {
      username: "",
      password: "",
    };

    return (
      <div className="col-md-12">
        <Formik
          initialValues={initialValues}
          validationSchema={this.validationSchema}
          onSubmit={this.handleLogin}
        >
          <Form>
            <div className="form-group m-1">
              <label htmlFor="username">Username</label>
              <Field name="username" type="text" className="form-control" />
              <ErrorMessage
                name="username"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group m-1">
              <label htmlFor="password">Password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group m-1">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group m-1">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    );
  }
}

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  return <LoginInternal navigate={navigate} />;
};
