import React, { Component } from "react";
import { Table } from "reactstrap";
import IProduct from "../../types/product.type";
import { ProductBattery } from "./ProductBattery";
import { ProductLabelPrint } from "./ProductLabelPrint";
import { ProductTestButton } from "./ProductTestButton";

type Props = {
  products: Array<IProduct> | null;
};

type State = {};

export class ProductTable extends Component<Props, State> {
  render() {
    return (
      <Table hover responsive size="sm" striped>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Supplier</th>
            <th>Type</th>
            <th>Status</th>
            <th>Battery</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.products && this.props.products.length > 0 ? (
            this.props.products.map((product) => (
              <tr key={product.id}>
                <td>{product.number}</td>
                <td>
                  {product.name}
                  <br />
                  IMEI: {product.imei}
                </td>
                <td>{product.supplier?.name}</td>
                <td>{product.type?.name}</td>
                <td>{product.status?.name}</td>
                <td>
                  <ProductBattery product={product} />
                </td>
                <td>
                  <ProductTestButton product={product} />
                </td>
                <td>
                  <ProductLabelPrint product={product} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>Loading...</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}
