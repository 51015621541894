import axios, { ResponseType } from "axios";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/admin";

export class AdminService {
  public static resetOrderTables() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(API_URL + "/resetOrderTables", undefined, config)
      .then((response) => {
        return response.data;
      });
  }

  public static async getDebugPostXml(): Promise<void> {
    const config = {
      headers: AuthService.getAuthHeaders(),
      responseType: "blob" as ResponseType,
    };

    return await axios
      .get(API_URL + "/debugPostXml", config)
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "debugpost.xml");
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }
}
