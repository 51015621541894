import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button } from "reactstrap";
import * as Yup from "yup";

type ChangeOrderAddressProps = {
  originalAddress: string;
  onSubmit: (address: string) => void;
  onBack: () => void;
};

export const ChangeOrderAddress = (
  props: ChangeOrderAddressProps
): React.ReactElement => {
  const initialValues = {
    old_address: props.originalAddress,
    address: props.originalAddress,
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string().trim().required("This field is required!"),
  });

  const submit = (formValue: { address: string }) => {
    props.onSubmit(formValue.address);
  };

  return (
    <div className="col-md-12">
      <div>Previous Address</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        <Form>
          <div className="form-group" style={{ minHeight: "60px" }}>
            <Field
              component="textarea"
              rows="4"
              name="old_address"
              type="text"
              className="form-control m-1"
              disabled
            />
          </div>
          <div>New Address:</div>
          <div className="form-group" style={{ minHeight: "120px" }}>
            <Field
              component="textarea"
              rows="4"
              name="address"
              type="text"
              className="form-control m-1"
              autoFocus={true}
            />
            <ErrorMessage
              name="address"
              component="div"
              className="alert alert-danger"
            />
          </div>

          <div className="form-group">
            <Button color="success" className="m-1" type="submit">
              OK
            </Button>
            <Button color="danger" className="m-1" onClick={props.onBack}>
              Back
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
