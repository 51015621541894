import { ErrorMessage, Field, Form, Formik } from "formik";
import { Component } from "react";
import { Navigate } from "react-router-dom";
import * as Yup from "yup";

import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { AuthService } from "../services/auth.service";
import { ZebraService } from "../services/zebra.service";

type Props = {};

type State = {
  loading: boolean;
  message: string;
  redirect: string | null;
};

export class Print extends Component<Props, State> {
  static displayName = Print.name;

  constructor(props: Props) {
    super(props);
    this.handleLabelPrint = this.handleLabelPrint.bind(this);

    this.state = {
      loading: false,
      message: "",
      redirect: null,
    };
  }

  componentDidMount() {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      this.setState({
        redirect: "/",
      });
      return;
    }
  }

  async handleLabelPrint(formValue: { productNumber?: number | null }) {
    const { productNumber } = formValue;

    if (productNumber) {
      this.setState({
        message: "",
        loading: true,
      });

      try {
        ZebraService.printLabel(String(productNumber), false, (error) =>
          this.setState({ message: error })
        );
      } catch (err) {
        this.handleError(err);
      }

      this.setState({
        loading: false,
      });
    }
  }

  handleError(error: any) {
    this.setState({
      message: `Unknown error: ${error}`,
      loading: false,
    });
    console.log(error);
  }

  validationSchema() {
    return Yup.object().shape({
      productNumber: Yup.number()
        .typeError("Numeric value")
        .required("This field is required!")
        .min(0, "Minimum a value")
        .test(
          "len",
          "Max 15 numbers",
          (val) => (val?.toString()?.length ?? 0) <= 15
        ),
    });
  }

  render() {
    const { loading, message } = this.state;

    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    const initialValues = {
      productNumber: null,
    };

    return (
      <div>
        <h1>Print product label</h1>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}
          onClick={() => this.setState({ loading: false })}
        >
          <CircularProgress />
        </Backdrop>

        <Formik
          initialValues={initialValues}
          validationSchema={this.validationSchema}
          onSubmit={this.handleLabelPrint}
        >
          <Form>
            <div className="form-group">
              <Field
                name="productNumber"
                type="text"
                className="form-control m-1"
              />
              <ErrorMessage
                name="productNumber"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-block m-1"
                disabled={loading}
              >
                <span> Print</span>
              </button>{" "}
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    );
  }
}
