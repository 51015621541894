import CopyIcon from "@mui/icons-material/ContentCopy";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { ProductIssueService } from "../../services/productissue.service";
import { ZebraService } from "../../services/zebra.service";
import { IRelatedOrder, ProductIssueType } from "../../types/productissue.type";

export const ProductIssue = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [copyToastOpen, setCopyToastOpen] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [relatedOrderItemRef, setRelatedOrderItemRef] = useState<
    IRelatedOrder | undefined
  >(undefined);
  const [notes, setNotes] = useState("");
  const [issueType, setIssueType] = useState(ProductIssueType.WARRANTY);

  useEffect(() => {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      setRedirect("/");
    }
  }, []);

  const findOrder = async () => {
    const identifier = searchInput.trim();
    if (identifier.length === 0) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await ProductIssueService.fetchRelatedOrder({
        identifier: identifier,
      });
      setRelatedOrderItemRef(response);
    } catch (e: any) {
      setErrorMessage(
        e.response.data ?? "Unknown error fetching related order"
      );
      console.error("Error fetching related order", e);
    }

    setIsLoading(false);
  };

  const copyClipboard = (text: string | undefined) => {
    if (text) {
      setCopyToastOpen(true);
      navigator.clipboard.writeText(text);
    }
  };

  const onClear = () => {
    setSearchInput("");
    setRelatedOrderItemRef(undefined);
    setIssueType(ProductIssueType.WARRANTY);
    setNotes("");
  };

  const onTextFieldKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // enter key
      findOrder();
    }
  };

  const createIssue = async () => {
    if (relatedOrderItemRef === undefined) {
      return;
    }

    setIsLoading(true);
    try {
      await ProductIssueService.createProductIssue({
        orderItemCrmGuid: relatedOrderItemRef.orderItemCrmGuid,
        orderNumber: relatedOrderItemRef.orderNumber,
        refurbedProductRef: relatedOrderItemRef.refurbedProductRef,
        issueType,
        notes,
      });

      const refurbedProductRef = relatedOrderItemRef.refurbedProductRef;
      // clear data (even if label print failed)
      onClear();
      console.log("Successfully created product issue, printing label now");

      await ZebraService.printLabel(refurbedProductRef, true, setErrorMessage);
    } catch (e: any) {
      setErrorMessage(
        e.response.data ?? "Unknown error creating product issue"
      );
      console.error("Error creating product issue", e);
    }
    setIsLoading(false);
  };

  const onInputChange = (text: string) => {
    setSearchInput(text);
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  let errorMessageElem = undefined;
  if (errorMessage) {
    errorMessageElem = (
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  }

  return (
    <div>
      {errorMessageElem}
      {loading ? <LinearProgress /> : undefined}
      <div style={{ padding: "10px" }} />
      <Stack direction="column" spacing={3}>
        <TextField
          autoFocus
          style={{ minWidth: "320px" }}
          id="outlined-basic"
          label="IMEI / Product Ref"
          variant="filled"
          size="small"
          required
          value={searchInput}
          onChange={(ev) => onInputChange(ev.target.value)}
          onKeyDown={onTextFieldKey}
          onFocus={(event) => {
            // Select all text when clicking on text field
            event.target.select();
          }}
        />
        <Stack direction="row">
          <Button variant="contained" disabled={loading} onClick={findOrder}>
            Find Order
          </Button>
        </Stack>
        <Divider />
        <Stack direction="row" spacing={5} style={{ alignItems: "center" }}>
          <div>Refurbed Product:</div>
          <div>
            <b>{relatedOrderItemRef?.refurbedProductRef ?? "?"}</b>
          </div>
          <IconButton
            disabled={!relatedOrderItemRef?.refurbedProductRef}
            onClick={() =>
              copyClipboard(relatedOrderItemRef?.refurbedProductRef)
            }
            color="primary"
          >
            <CopyIcon />
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={5} style={{ alignItems: "center" }}>
          <div>Related Order:</div>
          <div>
            <b>{relatedOrderItemRef?.orderNumber ?? "?"}</b>
          </div>
          <IconButton
            disabled={!relatedOrderItemRef?.orderNumber}
            onClick={() => copyClipboard(relatedOrderItemRef?.orderNumber)}
            color="primary"
          >
            <CopyIcon />
          </IconButton>
        </Stack>
        <Snackbar
          message="Copied to Clipboard"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={() => setCopyToastOpen(false)}
          open={copyToastOpen}
        />
        <Divider />
        <Stack direction="row">
          <FormControl>
            <FormLabel>Type</FormLabel>
            <RadioGroup
              row
              value={issueType}
              onChange={(_ev, val) => setIssueType(val as ProductIssueType)}
            >
              {Object.values(ProductIssueType).map((issueType) => (
                <FormControlLabel
                  key={issueType}
                  value={issueType}
                  control={<Radio />}
                  label={issueType}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack direction="row">
          <TextField
            style={{ minWidth: "700px" }}
            id="outlined-basic"
            label="Notes"
            variant="filled"
            multiline
            minRows={5}
            required
            value={notes}
            onChange={(ev) => setNotes(ev.target.value)}
            onFocus={(event) => {
              // Select all text when clicking on text field
              event.target.select();
            }}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            disabled={loading || !relatedOrderItemRef || !notes}
            onClick={createIssue}
          >
            Create Product Issue
          </Button>
          <Button variant="outlined" onClick={onClear}>
            Clear
          </Button>
        </Stack>
        <div style={{ padding: "10px" }} />
      </Stack>
    </div>
  );
};
