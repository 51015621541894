import axios from "axios";
import IProduct from "../types/product.type";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/product";

export class ProductService {
  public static getTestingProducts() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/testing", config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static getProduct(productnumber: string): Promise<IProduct> {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios.get(API_URL + "/" + productnumber, config).then((response) => {
      return response.data as IProduct;
    });
  }

  public static getProducts(productnumbers: string[]): Promise<IProduct[]> {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(API_URL + "/find", productnumbers, config)
      .then((response) => {
        return response.data as IProduct[];
      });
  }

  public static getProductLabel(
    productnumber: string,
    showSupplierWarranty: boolean
  ) {
    const config = {
      headers: AuthService.getAuthHeaders(),
      params: {
        showSupplierWarranty,
      },
    };
    return axios
      .get(API_URL + "/" + productnumber + "/label", config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static setBatteryCapacity(
    productnumber: string,
    batteryCapacity: number | null
  ) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/setBatteryCapacity",
        {
          productnumber,
          batteryCapacity,
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static setBatteryCapacityNone(productnumber: string) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/setBatteryCapacity",
        {
          productnumber,
          batterycapacity: 100,
          remarks: "No capacity known",
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static setBatteryCapacityMessage(productnumber: string) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/setBatteryCapacity",
        {
          productnumber,
          batterycapacity: 100,
          remarks: "Battery message",
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static testSuccessful(productnumber: string, remarks: string | null) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/testSuccessful",
        {
          productnumber,
          remarks,
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static testBatteryRepair(
    productnumber: string,
    remarks: string | null
  ) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/testBatteryRepair",
        {
          productnumber,
          remarks,
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static testRepair(
    productnumber: string,
    remarks: string | null,
    defect: string | null
  ) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/testRepair",
        {
          productnumber,
          remarks,
          defect,
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static testRma(
    productnumber: string,
    remarks: string | null,
    defect: string | null
  ) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/testRma",
        {
          productnumber,
          remarks,
          defect,
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static deleteProduct(id: number) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/delete",
        {
          id,
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }
}
