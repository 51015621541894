import type {} from "@redux-devtools/extension"; // required for devtools typing
import { produce } from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface ShippingFilterState {
  countryFilter: string[];
}

interface OpsToolState {
  shippingFilterState: ShippingFilterState;
  // Country which is used when registering new products (Wareneingang)
  countryLocation: string;
  setShippingCountryFilter: (filters: string[]) => void;
  setRegisterCountryLocation: (country: string) => void;
}

export const useOpsToolStore = create<OpsToolState>()(
  devtools(
    persist(
      (set) => ({
        shippingFilterState: { countryFilter: [] },
        countryLocation: "CH",
        setShippingCountryFilter: (filters: string[]) =>
          set(
            produce((state) => {
              state.shippingFilterState.countryFilter = filters;
            })
          ),
        setRegisterCountryLocation: (country: string) =>
          set(
            produce((state) => {
              state.countryLocation = country;
            })
          ),
      }),
      {
        name: "opstool-storage",
      }
    )
  )
);
