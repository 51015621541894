import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import IProduct from "../../types/product.type";
import ITest from "../../types/test.type";
import { ProductTest } from "./ProductTest";

import React from "react";
import Images from "../../images/images";
import { ProductService } from "../../services/product.service";
import { TestService } from "../../services/test.service";
import { ProductDefect } from "./ProductDefect";
import { DefectType } from "./defecttype.enum";

type Props = {
  product: IProduct | null;
};

enum ModalState {
  PRODUCT_TEST,
  PRODUCT_DEFECT,
  LOADING,
}

export const ProductTestButton = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState(ModalState.PRODUCT_TEST);
  const [defectType, setDefectType] = useState(DefectType.REPAIR);
  const [tests, setTests] = useState<ITest[] | null>(null);

  const unassignTest = () => {
    setTests(null);
  };

  const toggle = () => {
    const newModalOpenState = !modalOpen;
    setModalOpen(newModalOpenState);

    if (newModalOpenState) {
      // fetch m360 results if modal is being opened
      if (props.product?.m360Id) {
        TestService.getTestsById(props.product.m360Id)
          .then((responseData) => {
            setTests(responseData ?? null);
          })
          .catch((err) => handleError(err));
      } else if (props.product?.imei) {
        TestService.getTestsByImei(props.product.imei)
          .then((responseData) => {
            setTests(responseData ?? null);
          })
          .catch((err) => handleError(err));
      }
    }
  };

  const handleError = (error: any) => {
    console.log(error);
  };

  const onRma = () => {
    setDefectType(DefectType.RMA);
    setModalState(ModalState.PRODUCT_DEFECT);
  };

  const onDefectSubmit = (defect: string) => {
    if (props.product?.number) {
      setModalState(ModalState.LOADING);
      switch (defectType) {
        case DefectType.REPAIR:
          ProductService.testRepair(props.product.number, "", defect)
            .then((responseData) => {
              window.location.reload();
            })
            .catch((err) => handleError(err));
          break;
        case DefectType.RMA:
          ProductService.testRma(props.product.number, "", defect)
            .then((responseData) => {
              window.location.reload();
            })
            .catch((err) => handleError(err));
          break;
      }
    }
  };

  const onRepair = () => {
    setDefectType(DefectType.REPAIR);
    setModalState(ModalState.PRODUCT_DEFECT);
  };

  return (
    <div className="text-center">
      <Button color="primary" onClick={toggle}>
        <Images.ProductTestIcon />
      </Button>
      <Modal
        centered
        fade={false}
        size="lg"
        isOpen={modalOpen}
        toggle={toggle}
        onClosed={() => setModalState(ModalState.PRODUCT_TEST)}
      >
        <ModalHeader toggle={toggle}>
          <span>Test product {props.product?.number}</span>

          {tests !== null ? (
            <Button color="warning" className="m-1" onClick={unassignTest}>
              <Images.DoubleArrowIcon /> Unassign
            </Button>
          ) : (
            <div></div>
          )}
        </ModalHeader>
        <ModalBody>
          {/* keep component loaded */}
          <span hidden={modalState !== ModalState.PRODUCT_TEST}>
            <ProductTest
              tests={tests}
              product={props.product}
              onRepair={onRepair}
              onRma={onRma}
            />
          </span>
          {modalState === ModalState.PRODUCT_DEFECT ? (
            <ProductDefect
              onBack={() => {
                setModalState(ModalState.PRODUCT_TEST);
              }}
              onSubmit={onDefectSubmit}
              defectType={defectType}
            />
          ) : undefined}
          {modalState === ModalState.LOADING && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
