import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button } from "reactstrap";
import * as Yup from "yup";
import { DefectType } from "./defecttype.enum";

type ProductDefectProps = {
  defectType: DefectType;
  onSubmit: (defect: string) => void;
  onBack: () => void;
};

export const ProductDefect = (
  props: ProductDefectProps
): React.ReactElement => {
  const initialValues = {
    defect: "",
  };

  const validationSchema = Yup.object().shape({
    defect: Yup.string().trim().required("This field is required!"),
  });

  const submit = (formValue: { defect: string }) => {
    props.onSubmit(formValue.defect);
  };

  return (
    <div className="col-md-12">
      <h6>{props.defectType}</h6>
      <div>Please describe the defect</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        <Form>
          <div className="form-group" style={{ minHeight: "120px" }}>
            <Field
              name="defect"
              type="text"
              className="form-control m-1"
              autoFocus={true}
            />
            <ErrorMessage
              name="defect"
              component="div"
              className="alert alert-danger"
            />
          </div>

          <div className="form-group">
            <Button color="success" className="m-1" type="submit">
              OK
            </Button>
            <Button color="danger" className="m-1" onClick={props.onBack}>
              Back
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
