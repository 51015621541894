import { Component } from "react";
import { Navigate, useParams } from "react-router-dom";
import IProduct from "../../types/product.type";

import React from "react";
import { AuthService } from "../../services/auth.service";
import { ProductService } from "../../services/product.service";

type Props = { id: string | undefined };

type State = {
  redirect: string | null;
  product: IProduct | null;
};

// TODO: Rewrite as functional component
class ProductInternal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      redirect: null,
      product: null,
    };
  }

  componentDidMount() {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      this.setState({
        redirect: "/",
      });
      return;
    }

    if (this.props.id) {
      ProductService.getProduct(this.props.id)
        .then((responseData) => {
          if (responseData) {
            this.setState({
              product: responseData,
            });
          } else {
            this.setState({
              product: null,
            });
          }
        })
        .catch((err) => this.handleError(err));
    }
  }

  handleError(error: any) {
    console.log(error);
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div>
        <h1>Product {this.state.product?.number}</h1>
        <p>{this.state.product?.imei}</p>
      </div>
    );
  }
}

export const Product = (): JSX.Element => {
  const params = useParams();
  return <ProductInternal id={params.id} />;
};
