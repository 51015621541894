import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import ReactTimeAgo from "react-time-ago";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import IOrder from "../../types/order.type";

import Images from "../../images/images";
import LabelType from "../../services/labeltype.enum";
import { OrderService } from "../../services/order.service";
import { SyncService } from "../../services/sync.service";
import { ZebraService } from "../../services/zebra.service";
import { useOpsToolStore } from "../../store/OpsStore";
import IOrderItem from "../../types/orderitem.type";
import PaymentMethod from "../../types/paymentmethod.enum";
import IShippedAllResponse from "../../types/shippedallresponse.type";
import { ChangeOrderAddress } from "./ChangeOrderAddress";

type Props = {
  initializing: boolean;
  orders: Array<IOrder> | null;
  onReload: () => void;
};

const isShippingAllowed = (
  paymentMethod: PaymentMethod | null | undefined,
  labelType: LabelType
): boolean => {
  if (paymentMethod === undefined || paymentMethod === null) {
    return false;
  }

  // For Rechnung, only registered mail is allowed
  if (paymentMethod === PaymentMethod.Rechnung_Ideal) {
    return (
      labelType === LabelType.APostRegistered ||
      labelType === LabelType.PostPacPriorityRegistered
    );
  }

  return true;
};

export const OrderList = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [showRegenerationModal, setShowRegenerationModal] = useState(false);
  const [showChangeOrderAddressModal, setShowChangeOrderAddressModal] =
    useState(false);
  const [showPrintingModal, setShowPrintingModal] = useState(false);
  const [showShippedAll, setShowShippedAll] = useState(false);
  const [infoText, setInfoText] = useState<string | undefined>(undefined);

  const [trackingNumber, setTrackingNumber] = useState<string | undefined>(
    undefined
  );
  const [currentOrder, setCurrentOrder] = useState<IOrder | undefined>(
    undefined
  );
  const [countryFilters, setCountryFilters] = useOpsToolStore((state) => [
    state.shippingFilterState.countryFilter,
    state.setShippingCountryFilter,
  ]);

  const getOrderItemLocation = (orderItem: IOrderItem): string | undefined => {
    if (!orderItem.refurbedProduct) {
      return undefined;
    }

    if (!orderItem.latestLocation) {
      return "<Unknown Location>";
    }

    return `${orderItem.latestLocation} (${
      orderItem.latestLocationCountry ?? "??"
    })`;
  };

  const initPrinting = (order: IOrder) => {
    setLoading(true);
    if (order.labelZpl && order.tracking) {
      setShowRegenerationModal(true);
      setShowPrintingModal(false);
      setTrackingNumber(order.tracking);
      setCurrentOrder(order);
    } else {
      setShowRegenerationModal(false);
      setShowPrintingModal(true);
      setTrackingNumber(undefined);
      setCurrentOrder(order);
    }
    setLoading(false);
  };

  const printAgain = async () => {
    setLoading(true);

    if (currentOrder) {
      try {
        const responseData = await OrderService.getLabel(currentOrder.id);
        if (responseData.zpl) {
          await ZebraService.print(responseData.zpl);
          props.onReload();
        }
      } catch (error) {
        handleError(error);
      }
    }
    setShowRegenerationModal(false);
    setLoading(false);
    setCurrentOrder(undefined);
  };

  const generateLabel = async (labelType: LabelType) => {
    setLoading(true);
    if (currentOrder) {
      try {
        const responseData = await OrderService.generateLabel(
          currentOrder.id,
          labelType
        );
        try {
          if (responseData.zpl) {
            await ZebraService.print(responseData.zpl);
            props.onReload();
          }
        } catch (error) {
          handleError(error);
          alert(
            "Error while generating the address. Check and change the address lines."
          );
        }
      } catch (err) {
        handleError(err);
      }
    }

    setShowPrintingModal(false);
    setLoading(false);
    setCurrentOrder(undefined);
  };

  const onUpdateAddress = async (newAddress: string) => {
    if (!newAddress || newAddress === currentOrder?.rawAddressLines) {
      // If no new address defined or address is unchanged, do nothing
      return;
    }

    setShowChangeOrderAddressModal(false);
    setLoading(true);

    if (currentOrder && newAddress !== currentOrder?.rawAddressLines) {
      try {
        await OrderService.updateOrderAddress(
          currentOrder.number,
          currentOrder.orderCrmGuid,
          newAddress
        );
        await SyncService.syncOrders();
        props.onReload();
      } catch (err) {
        handleError(err);
      }
    }

    setLoading(false);
    setCurrentOrder(undefined);
  };

  const resetTrackingNumber = async () => {
    setLoading(true);
    if (currentOrder) {
      try {
        await OrderService.resetTracking(currentOrder.id);
        props.onReload();
      } catch (err) {
        handleError(err);
      }
    }

    setShowRegenerationModal(false);
    setLoading(false);
    setCurrentOrder(undefined);
  };

  const closeRegenerationModal = () => {
    setShowRegenerationModal(false);
    setCurrentOrder(undefined);
  };

  const closePrintingModal = () => {
    setShowPrintingModal(false);
    setCurrentOrder(undefined);
  };

  const doShowShippedAll = (order: IOrder) => {
    setCurrentOrder(order);
    setShowShippedAll(true);
  };

  const doShowChangeOrderAddress = (order: IOrder) => {
    setCurrentOrder(order);
    setShowChangeOrderAddressModal(true);
  };

  const shippedAll = async () => {
    setShowShippedAll(false);
    setLoading(true);

    let shippedAllResponse: IShippedAllResponse | undefined;

    if (currentOrder) {
      try {
        shippedAllResponse = await OrderService.shippedAll(currentOrder.id);
        props.onReload();
      } catch (err) {
        handleError(err);
      }
    }

    setLoading(false);
    setCurrentOrder(undefined);

    if (shippedAllResponse && !shippedAllResponse.zendeskUpdated) {
      setInfoText(
        `Could not set Tracking Number on Zendesk Ticket. Tracking Number was: ${shippedAllResponse.trackingNr}`
      );
    }
  };

  const closeShippedAll = () => {
    setShowShippedAll(false);
    setCurrentOrder(undefined);
  };

  const handleError = (error: any) => {
    console.log(error);
  };

  const getCountryValues = (): string[] => {
    const defaultValues = ["CH", "DE"];
    const availableCountries = new Set(defaultValues);
    props.orders
      ?.flatMap((order) => order.orderitems)
      .map((orderItem) => orderItem.latestLocationCountry)
      .forEach((country) => {
        if (country) {
          availableCountries.add(country);
        }
      });

    return Array.from(availableCountries);
  };

  const getLabelButton = (labelType: LabelType, text: string) => {
    return (
      <Button
        disabled={
          loading || !isShippingAllowed(currentOrder?.paymentMethod, labelType)
        }
        color="warning"
        className="m-1"
        onClick={() => generateLabel(labelType)}
      >
        <Images.BiPrinter /> {text}
      </Button>
    );
  };

  let orderList = undefined;

  if (props.initializing) {
    orderList = <span className="spinner-border spinner-border-sm" />;
  } else if (props.orders && props.orders.length > 0) {
    let applicableOrders = props.orders;

    if (countryFilters.length > 0) {
      const countryFilterSet = new Set(countryFilters);
      applicableOrders = applicableOrders.filter(
        (order) =>
          order.orderitems.every(
            (orderItem) => !orderItem.latestLocationCountry
          ) ||
          order.orderitems.some(
            (orderItem) =>
              orderItem.latestLocationCountry &&
              countryFilterSet.has(orderItem.latestLocationCountry)
          )
      );
    }

    orderList = applicableOrders.map((order) => (
      <Card key={order.id} className="my-2" color="light">
        <CardBody>
          <div className="row">
            <div className="col-2">
              <h4 className="font-underline">{order.number}</h4>
              {order.orderDate ? (
                <ReactTimeAgo
                  date={Date.parse(order.orderDate)}
                  locale="en-US"
                  timeStyle="round"
                />
              ) : (
                <span></span>
              )}
              <br />
              <span>{order.shippingType}</span>
              <br />
              <span>💵 {order.paymentMethod}</span>
              <br />
              <Badge
                color={
                  order.type === "Refurbished"
                    ? "success"
                    : order.type === "Buyback"
                    ? "infodark"
                    : order.type === "Repair"
                    ? "danger"
                    : order.type === "Spare parts"
                    ? "warning"
                    : "info"
                }
              >
                {order.type}
              </Badge>
              <br />
              <span>
                {order.addressLines
                  ?.split("\n")
                  .filter((line) => line.trim())
                  .map((line, i) => (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  ))}
              </span>
            </div>
            <div className="col-8">
              <Table hover>
                <thead>
                  <tr>
                    <th>Qty</th>
                    <th>Product</th>
                    <th>Battery</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderitems && order.orderitems.length > 0 ? (
                    order.orderitems.map((orderitem) => (
                      <tr key={orderitem.id}>
                        <th scope="row">
                          <span>{orderitem.count}</span>
                        </th>
                        <td>
                          {orderitem.refurbedProduct ? (
                            <span>
                              {orderitem.refurbedProduct} - {orderitem.name}
                            </span>
                          ) : (
                            <span>{orderitem.name}</span>
                          )}{" "}
                          {orderitem.batteryChange ? (
                            <Badge color="danger" title="Battery Change">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-battery-half"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2 6h5v4H2V6z" />
                                <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z" />
                              </svg>
                            </Badge>
                          ) : (
                            <span></span>
                          )}{" "}
                          {orderitem.hydrogel ? (
                            <Badge
                              color="info"
                              title="Hydrogel Screen Protector"
                            >
                              <Images.BiPhone />
                            </Badge>
                          ) : (
                            <span></span>
                          )}
                          <div style={{ color: "grey" }}>
                            {getOrderItemLocation(orderitem)}
                          </div>
                        </td>
                        <td>
                          <span>
                            {orderitem.batteryCapacity
                              ? orderitem.batteryCapacity + "%"
                              : ""}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <th scope="row"></th>
                      <td>
                        <span>no orderitems found</span>
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="col text-right">
              <Button
                color="secondary m-1"
                disabled={loading}
                onClick={() => initPrinting(order)}
              >
                <Images.BiPrinter />
              </Button>
              {order.tracking || order.labelZpl ? (
                <span>
                  <Button
                    color="success m-1"
                    disabled={loading}
                    onClick={() => doShowShippedAll(order)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check2-all"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                    </svg>
                  </Button>
                </span>
              ) : (
                <span></span>
              )}
              <br />
              <span className="font-italic">{order.tracking}</span>
              <br />
              <Button
                color="secondary m-1"
                disabled={loading}
                onClick={() => doShowChangeOrderAddress(order)}
              >
                Change Address ✏️
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    ));
  } else {
    orderList = (
      <div>
        <span>Looks like you shipped all!</span>{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-emoji-sunglasses"
          viewBox="0 0 16 16"
        >
          <path d="M4.968 9.75a.5.5 0 1 0-.866.5A4.498 4.498 0 0 0 8 12.5a4.5 4.5 0 0 0 3.898-2.25.5.5 0 1 0-.866-.5A3.498 3.498 0 0 1 8 11.5a3.498 3.498 0 0 1-3.032-1.75zM7 5.116V5a1 1 0 0 0-1-1H3.28a1 1 0 0 0-.97 1.243l.311 1.242A2 2 0 0 0 4.561 8H5a2 2 0 0 0 1.994-1.839A2.99 2.99 0 0 1 8 6c.393 0 .74.064 1.006.161A2 2 0 0 0 11 8h.438a2 2 0 0 0 1.94-1.515l.311-1.242A1 1 0 0 0 12.72 4H10a1 1 0 0 0-1 1v.116A4.22 4.22 0 0 0 8 5c-.35 0-.69.04-1 .116z" />
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-1 0A7 7 0 1 0 1 8a7 7 0 0 0 14 0z" />
        </svg>
      </div>
    );
  }

  let printButtons = (
    <Button
      disabled={loading}
      color="success"
      className="m-1"
      onClick={() => generateLabel(LabelType.Default)}
      visible="false"
    >
      <Images.BiPrinter /> Just print
    </Button>
  );
  if (currentOrder?.type === "Buyback") {
    printButtons = (
      <>
        <Button
          disabled={loading}
          color="success"
          className="m-1"
          onClick={() => generateLabel(LabelType.BuybackMini)}
          visible="false"
        >
          <Images.BiPrinter /> Buyback Small
        </Button>
        <Button
          disabled={loading}
          color="success"
          className="m-1"
          onClick={() => generateLabel(LabelType.BuybackLarge)}
          visible="false"
        >
          <Images.BiPrinter /> Buyback Large
        </Button>
      </>
    );
  }

  return (
    <div>
      <Modal
        toggle={() =>
          setShowChangeOrderAddressModal(!showChangeOrderAddressModal)
        }
        centered
        fade={false}
        size="lg"
        isOpen={showChangeOrderAddressModal}
      >
        <ModalHeader>
          <span>Update Order Address: {currentOrder?.number}</span>
        </ModalHeader>
        <ModalBody>
          <ChangeOrderAddress
            originalAddress={currentOrder?.rawAddressLines ?? ""}
            onSubmit={onUpdateAddress}
            onBack={() => setShowChangeOrderAddressModal(false)}
          />
        </ModalBody>
      </Modal>

      <Modal
        toggle={() => setShowRegenerationModal(!showRegenerationModal)}
        centered
        fade={false}
        size="lg"
        isOpen={showRegenerationModal}
      >
        <ModalHeader>
          <span>Current tracking number {trackingNumber}</span>
        </ModalHeader>
        <ModalBody>
          <Button
            disabled={loading}
            color="success"
            className="m-1"
            onClick={printAgain}
            visible="false"
          >
            <Images.BiPrinter /> Just print again
          </Button>
          <Button
            disabled={loading}
            color="warning"
            className="m-1"
            onClick={resetTrackingNumber}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>{" "}
            Reset tracking number
          </Button>
          <Button
            disabled={loading}
            color="secondary"
            className="m-1"
            onClick={closeRegenerationModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fillRule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>{" "}
            Cancel
          </Button>
        </ModalBody>
      </Modal>

      <Modal
        centered
        fade={false}
        size="lg"
        toggle={() => setShowPrintingModal(!showPrintingModal)}
        isOpen={showPrintingModal}
      >
        <ModalHeader>Print</ModalHeader>
        <ModalBody>
          {printButtons}
          <br />
          {getLabelButton(LabelType.Midi, "Midi A-Post (1.70)")}
          {getLabelButton(LabelType.Gross, "Gross A-Post (2.50)")}
          <br />
          {getLabelButton(LabelType.MidiAPlus, "Midi A-Post Plus (2.90)")}
          {getLabelButton(
            LabelType.APostRegistered,
            "A-Post Einschreiben (5.80)"
          )}
          {getLabelButton(LabelType.GrossAPlus, "Gross A-Post Plus (4.70)")}
          <br />
          {getLabelButton(LabelType.Package, "Package (10.50)")}
          {getLabelButton(
            LabelType.PostPacPriorityRegistered,
            "Package Signature (12.00)"
          )}
          {getLabelButton(LabelType.GASPackage, "GAS Package")}
          <br />
          <Button
            disabled={loading}
            color="secondary"
            className="m-1"
            onClick={closePrintingModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fillRule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>{" "}
            Cancel
          </Button>
        </ModalBody>
      </Modal>

      <Modal
        toggle={() => setShowShippedAll(!showShippedAll)}
        centered
        fade={false}
        size="lg"
        isOpen={showShippedAll}
      >
        <ModalHeader>
          <span>Confirm shipped all</span>
        </ModalHeader>
        <ModalBody>
          <Button
            disabled={loading}
            color="success"
            className="m-1"
            onClick={() => shippedAll()}
            visible="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-check2-all"
              viewBox="0 0 16 16"
            >
              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
            </svg>{" "}
            Confirm
          </Button>
          <Button
            disabled={loading}
            color="secondary"
            className="m-1"
            onClick={() => closeShippedAll()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fillRule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>{" "}
            Cancel
          </Button>
        </ModalBody>
      </Modal>

      <Dialog
        open={infoText !== undefined}
        onClose={() => setInfoText(undefined)}
      >
        <DialogTitle>Information</DialogTitle>
        <DialogContent>
          <DialogContentText>{infoText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoText(undefined)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Divider />
      {loading ? <LinearProgress /> : undefined}
      <Stack style={{ paddingTop: "10px" }} direction="row-reverse">
        <Autocomplete
          disablePortal
          autoHighlight
          multiple
          id="country-filter-select"
          options={getCountryValues()}
          value={countryFilters}
          sx={{ width: 250 }}
          size="small"
          onChange={(_, val) => setCountryFilters(val)}
          renderInput={(params) => (
            <TextField {...params} label="Stock Country" />
          )}
        />
      </Stack>
      {orderList}
    </div>
  );
};
