enum LabelType {
  Default = 0,
  Midi = 1,
  Gross = 2,
  MidiAPlus = 3,
  GrossAPlus = 4,
  Package = 5,
  GASPackage = 6,
  /**
   * A buyback order for a small package (e.g. phone)
   */
  BuybackMini = 7,
  /**
   * A buyback order for a large package (e.g. laptop)
   */
  BuybackLarge = 8,
  /**
   * A Post Einschreiben (Registered Mail)
   */
  APostRegistered = 9,
  /**
   * A Post Einschreiben (Registered Mail)
   */
  PostPacPriorityRegistered = 10,
}

export default LabelType;
