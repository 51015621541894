import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { Button } from "@mui/material";
import React from "react";
import { AdminService } from "../services/admin.service";
import { AuthService } from "../services/auth.service";

export const Admin = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      setRedirect("/");
    }
  }, []);

  const handleResetOrderTables = async () => {
    setIsLoading(true);

    try {
      await AdminService.resetOrderTables();
    } catch (e) {
      setErrorMessage("Error resetting order tables");
      console.error("Error resetting order tables", e);
    }
  };

  const handleCreatePostXml = async () => {
    try {
      await AdminService.getDebugPostXml();
    } catch (e: any) {
      console.error("Error getting debug xml", e);
      e.response?.data
        ?.text()
        .then((txt: string) =>
          setErrorMessage(`Error getting debug xml: ${txt}`)
        );
    }
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  let errorMessageElem = undefined;
  if (errorMessage) {
    errorMessageElem = (
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  }

  return (
    <div>
      {errorMessageElem}
      <span>
        <Button onClick={handleCreatePostXml}>Download Post XML</Button>
      </span>
    </div>
  );
};
