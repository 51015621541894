import * as React from "react";
import { Component } from "react";
import { Table } from "reactstrap";

import ReactTimeAgo from "react-time-ago";

import { TestService } from "../../services/test.service";
import IProduct from "../../types/product.type";
import ITest from "../../types/test.type";

type Props = {
  tests: Array<ITest> | null;
  product: IProduct | null;
};

type State = {
  loading: boolean;
  message: string;
};

export class TestTable extends Component<Props, State> {
  static displayName = TestTable.name;

  constructor(props: Props) {
    super(props);
    this.assignTest = this.assignTest.bind(this);

    this.state = {
      loading: false,
      message: "",
    };
  }

  assignTest(m360Id: string | null) {
    this.setState({
      message: "",
      loading: true,
    });

    if (this.props.product?.number && m360Id) {
      TestService.assignTestToProduct(this.props.product.number, m360Id)
        .then((responseData) => {
          window.location.reload();
        })
        .catch((err) => this.handleError(err));
    }
  }

  handleError(error: any) {
    this.setState({
      loading: false,
      message: "Error while saving. Check the console",
    });
    console.log(error);
  }

  render() {
    const { loading } = this.state;

    return (
      <Table hover responsive size="sm" striped>
        <thead>
          <tr>
            <th>IMEI</th>
            <th>M360 Id</th>
            <th>Model</th>
            <th>Connection time</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.tests && this.props.tests.length > 0 ? (
            this.props.tests.map((test) => (
              <tr key={test.id}>
                <td>{test.imei}</td>
                <td>{test.m360Id}</td>
                <td>{test.modelName}</td>
                <td>
                  {test.connectionTime ? (
                    <ReactTimeAgo
                      date={Date.parse(test.connectionTime.toString())}
                      locale="en-US"
                      timeStyle="round"
                    />
                  ) : (
                    <div></div>
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-primary btn-block"
                    disabled={loading}
                    onClick={() => this.assignTest(test.m360Id)}
                  >
                    <span> Assign</span>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>Loading...</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}
