import axios from "axios";
import { isExpired } from "react-jwt";
import IUserToken from "../types/usertoken.type";

const API_URL = "/api/v1.0/login";

export class AuthService {
  public static login(username: string, password: string) {
    return axios
      .post(API_URL + "/auth", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("usertoken", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  public static logout() {
    localStorage.removeItem("usertoken");
  }

  public static getCurrentUser(): IUserToken {
    const userStr = localStorage.getItem("usertoken");
    if (userStr) return JSON.parse(userStr);

    return { expiringOn: null, token: null, username: null };
  }

  public static isUserAuthenticated(): boolean {
    const userToken = this.getCurrentUser();
    if (userToken.token) {
      return !isExpired(userToken.token);
    }
    return false;
  }

  public static getAuthHeaders(): any {
    var usertoken = AuthService.getCurrentUser();
    if (usertoken && usertoken.token) {
      return { Authorization: "Bearer " + usertoken.token };
    } else {
      return {};
    }
  }
}
