import { Component } from "react";
import IProduct from "../types/product.type";
import { SyncTesting } from "./SyncTesting";
import { ProductTable } from "./product/ProductTable";

import React from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../services/auth.service";
import { ProductService } from "../services/product.service";

type Props = {};

type State = {
  redirect: string | null;
  products: Array<IProduct> | null;
};

export class Testing extends Component<Props, State> {
  static displayName = Testing.name;

  constructor(props: Props) {
    super(props);

    this.state = {
      redirect: null,
      products: null,
    };
  }

  componentDidMount() {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      this.setState({
        redirect: "/",
      });
      return;
    }

    ProductService.getTestingProducts()
      .then((responseData) => {
        this.setState({
          products: responseData,
        });
      })
      .catch((err) => this.handleError(err));
  }

  handleError(error: any) {
    console.log(error);
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div>
        <div className="title-sync-grid">
          <h1>Testing</h1>
          <SyncTesting />
        </div>
        <ProductTable products={this.state.products} />
      </div>
    );
  }
}
